//
//
//
//
//
//
//
//
//

import HeadLogo from "@/components/headLogo/headLogo";
import Footer from "@/components/footer/footer";
import announceApi from "@/api/announce";
export default {
  components: {
    HeadLogo,
    Footer,
  },
  data() {
    return {
      html: null,
    };
  },
  mounted() {
    announceApi.getTerm().then((res) => {
      console.log(res);
      if (res) {
        this.html = res[0].content;
      }
    });
  },
};
